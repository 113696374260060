import React, { useEffect, useCallback, useRef } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Typography, styled, useTheme } from '@mui/material';
import { Close as CloseIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { waitForTransactionReceipt } from '@wagmi/core';

import orderbookService from 'services/orderbook';

import wagmiConfig from 'wagmiConfig';
import { CHAIN_ID, REDIRECT_LINK_EXPLORER, SOMETHING_WENT_WRONG } from 'constants/index';
import useNotification from 'hooks/useNotification';
import { ellipsisAddress } from 'utils/helper';
import { useMatchBuyOrderTx, useCheckIfMakerIsOwner } from 'utils/onchain/exchange';
import { openLink } from 'utils/openLink';
import { constructBuyOrders } from 'utils/orderbook/buy';
import { getFullMediaUrl } from 'utils/image';
import { getListingOrderKey } from 'utils/order';

import { GeneralFlexBox, LinkText, StyledCircularProgress } from 'components/StyledComponents';
import {
  CheckoutModalRow,
  ModalHeaderContainer,
  ModalIconButton,
} from '../components/styledComponents';
import SocialMediaShare from 'components/SocialMediaShare';

const MainContainer = styled(GeneralFlexBox)(() => ({
  width: '100%',
  flexDirection: 'column',
  // Move footer row to bottom screen when in vertical small screen
  '@media(max-width: 600px)': {
    height: '100%',
    justifyContent: 'space-between',
  },
}));

const MiddleRow = styled(CheckoutModalRow)(({ path }) => ({
  flexGrow: 1,
  alignItems: 'center',
  flexDirection: 'column',
  padding: '20px 24px 16px 24px',

  '@media (max-width: 500px)': {
    justifyContent: path !== 'buy' ? 'center' : 'flex-start',
  },
}));

const ProcessingBox = styled(GeneralFlexBox)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.decorative}`,
  borderRadius: '8px',
  width: '330px',
  padding: theme.spacing(3),
  marginTop: theme.spacing(2),
  justifyContent: 'space-between',
}));

const CheckoutFooterRow = styled(CheckoutModalRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.separator}`,
}));

const ProcessingThumbnail = styled('img')(({ theme }) => ({
  width: 112,
  height: 112,
  borderRadius: 5,
  border: `1px solid ${theme.palette.border.decorative}`,
}));

const SmallCircularProgress = styled(StyledCircularProgress)(({ theme }) => ({
  padding: '4px',
  border: `1px solid ${theme.palette.border.decorative}`,
}));

const SmallCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.decorative}`,
  borderRadius: '50%',
  padding: 0,
  color: theme.palette.button.active,
}));

const ClearLinkText = styled(LinkText)(({ theme }) => ({
  opacity: 1,
  color: theme.palette.link.primary,
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: 500,

  '&:hover': {
    color: theme.palette.button.primary,
    textDecoration: 'none',
  },
  '&:foucs': {
    color: theme.palette.button.primary,
    textDecoration: 'none',
  },
}));

/********************  Main Component  ********************/
const ProcessingPart = ({ data, closeModal, address, path }) => {
  const theme = useTheme();

  const ref = useRef(null);
  const showNotification = useNotification();

  const [processing, setProcessing] = useStateIfMounted(true);
  const [message, setMessage] = useStateIfMounted('Processing');
  const [txHash, setTxHash] = useStateIfMounted('');
  const { metadata } = data;

  const assetLink = `${process.env.REACT_APP_PUBLIC_URL}/asset/${data.nftContract.address}/${data.tokenId}`;
  const handleMatchBuyOrderTx = useMatchBuyOrderTx();

  const checkIfMakerIsOwner = useCheckIfMakerIsOwner({
    contractAddress: data.nftContract.address,
  });

  const showFailedMessage = (message = SOMETHING_WENT_WRONG) => {
    setMessage('Failed');
    showNotification({
      message: message,
      type: 'error',
    });
    return closeModal();
  };

  const prepareAndExecuteOrder = useCallback(async () => {
    let makerOrderOB;
    const orderKey = getListingOrderKey(data);

    try {
      makerOrderOB = await orderbookService.getOrder(orderKey);
    } catch (err) {
      showFailedMessage('Sorry, this listing is not available anymore.');
      return setProcessing(false);
    }

    const owner = await checkIfMakerIsOwner({
      contractAddress: makerOrderOB.MakerAsset.Contract,
      maker: makerOrderOB.MakerAsset.Owner,
      tokenId: makerOrderOB.MakerAsset.TokenID,
    });
    if (!owner) {
      showFailedMessage('Sorry, this listing is not available anymore.');
      setProcessing(false);
      return null;
    }

    setMessage(`Please confirm the transaction`);
    const { makerOrder, takerOrder } = constructBuyOrders(makerOrderOB, address);
    const hash = await handleMatchBuyOrderTx({
      amount: makerOrderOB.TakerAsset.Value,
      makerOrder,
      takerOrder,
      matchAllowanceSignature: makerOrderOB.MatchAllowanceSignature,
      makerSign: makerOrderOB.MakerSign,
      matchBeforeTimestamp: makerOrderOB.MatchBeforeTimestamp,
    });

    if (!hash) {
      showNotification({
        message: `Transaction rejected. Please try again`,
        type: 'error',
      });
      return closeModal();
    } else {
      try {
        setTxHash(hash);
        setMessage('Confirming transaction...');
        await waitForTransactionReceipt(wagmiConfig, { hash });
        setMessage(`Processed`);
        showNotification({
          message: `Congratulations, You have bought ${
            data.name ?? data.metadata.name ?? 'an NFT'
          }.`,
          type: 'success',
        });
      } catch (err) {
        console.error(err);
        return showFailedMessage(err.reason);
      } finally {
        setProcessing(false);
      }
    }
  });

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
      prepareAndExecuteOrder();
    }
  }, []);

  useEffect(() => {
    if (!processing && message === 'Processed') {
      data.callback && data.callback();
    }
  }, [message, processing]);

  return (
    <MainContainer direction="column" width="100%">
      <ModalHeaderContainer>
        <Typography fontSize={24} fontWeight={500}>
          {processing ? 'Processing' : 'Processed'}
        </Typography>
        <ModalIconButton onClick={closeModal}>
          <CloseIcon style={{ fill: theme.palette.icon.tertiary }} />
        </ModalIconButton>
      </ModalHeaderContainer>
      <MiddleRow path={path}>
        <ProcessingThumbnail
          src={getFullMediaUrl(data.thumbnailPathName, data.rawFileUrl)}
          alt=""
        />
        <Typography fontSize={12} lineHeight="16px" fontWeight={500} marginTop={theme.spacing(2)}>
          {processing && message !== 'Processed'
            ? `Processing your purchase of ${metadata?.name || `Item name #${data.tokenId}`}`
            : `You successfully purchased ${metadata?.name || `Item name #${data.tokenId}`}`}
        </Typography>
        <ProcessingBox>
          <GeneralFlexBox direction="column" align="flex-start">
            <Typography
              fontSize="14px"
              lineHeight="20px"
              fontWeight={500}
              color={theme.palette.text.tertiary}
              marginBottom="10px"
            >
              Status
            </Typography>
            <GeneralFlexBox height={24}>
              {processing ? (
                <>
                  <SmallCircularProgress size={24} style={{ height: 'auto' }} />
                  <Typography
                    fontSize="14px"
                    lineHeight="20px"
                    color={theme.palette.text.secondary}
                    marginLeft="10px"
                  >
                    {message}
                  </Typography>
                </>
              ) : (
                <>
                  <SmallCheckCircleIcon
                    size={17}
                    color={message === 'Processed' ? 'success' : 'failed'}
                    style={{ height: 'auto' }}
                  />
                  <Typography
                    fontSize="14px"
                    lineHeight="20px"
                    color={theme.palette.text.secondary}
                    marginLeft="10px"
                  >
                    {message}
                  </Typography>
                </>
              )}
            </GeneralFlexBox>
          </GeneralFlexBox>
          <GeneralFlexBox direction="column" align="flex-start">
            <Typography
              fontSize="14px"
              lineHeight="20px"
              fontWeight={500}
              color={theme.palette.text.tertiary}
              marginBottom="10px"
            >
              Transaction Hash
            </Typography>
            <GeneralFlexBox height={24}>
              <ClearLinkText
                onClick={() => openLink(`${REDIRECT_LINK_EXPLORER[CHAIN_ID]}/tx/${txHash}`)}
              >
                {ellipsisAddress(txHash)}
              </ClearLinkText>
            </GeneralFlexBox>
          </GeneralFlexBox>
        </ProcessingBox>
      </MiddleRow>
      <GeneralFlexBox direction="column" width="100%">
        <Typography
          padding={theme.spacing(0, 0, 1, 0)}
          fontWeight={500}
          fontSize={14}
          lineHeight="20px"
        >
          Share
        </Typography>
        <CheckoutFooterRow>
          <SocialMediaShare url={assetLink} />
        </CheckoutFooterRow>
      </GeneralFlexBox>
    </MainContainer>
  );
};

export default ProcessingPart;
