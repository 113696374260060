import React from 'react';
import { Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import moment from 'moment';

import { getFullMediaUrl } from 'utils/image';

import { StandardButton } from 'components/Button';
import { GeneralFlexBox } from 'components/StyledComponents';
import { CheckoutHeadingRow, CheckoutModalRow } from '../../components/styledComponents';
import { CurrencyIcon } from 'components/Currency';
import EnableWNRG from './EnableWNRG';
import ConfirmOffer from './ConfirmOffer';
import OfferDetails from './OfferDetails';

export const OFFER_PROCESS_STEP = {
  WETH_ENABLE: 0,
  CONFIRM_OFFER: 1,
  POST_OFFER: 2,
};

// Thumbnail is the same in every screen size
const MakeOfferThumbnail = styled('img')(({ theme }) => ({
  width: 72,
  height: 72,
  borderRadius: 5,
  border: `2px solid ${theme.palette.border.decorative}`,
}));

const MiddleRow = styled(CheckoutModalRow)(() => ({
  flexGrow: 1,
  alignItems: 'flex-start',
  flexDirection: 'column',
}));

const CheckoutFooterRow = styled(CheckoutModalRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.separator}`,
}));

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'inherit',
  padding: theme.spacing(1.2, 0),
}));

const ContentHeading = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const SubHeading = styled(Typography)(({ theme, color }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: color ?? theme.palette.text.tertiary,
  margin: '0px',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  padding: theme.spacing(1.2, 0),
}));

/********************  Main Component  ********************/
const OfferProcess = ({
  data,
  expiredDate,
  bidAmount,
  closeModal,
  panel,
  wethProcessing,
  wethFailed,
  onApproveWETH,
  confirmFailed,
  confirmProcessing,
  onConfirmOffer,
}) => {
  const theme = useTheme();

  return (
    <>
      <CheckoutHeadingRow
        style={{
          display: 'flex',
          justifyContent: 'start',
          height: '100%',
        }}
      >
        <MakeOfferThumbnail src={getFullMediaUrl(data.thumbnailPathName)} alt="" />
        <GeneralFlexBox direction="column" padding={theme.spacing(2)} align="flex-start">
          <Typography
            sx={{
              lineHeight: '24px',
              letterSpacing: ' 0.15px',
              fontSize: 16,
              fontWeight: 600,
              color: theme.palette.text.main,
            }}
          >
            {data?.metadata?.name || `Item name #${data.tokenId}`}
          </Typography>
          <Typography
            sx={{
              lineHeight: '16px',
              letterSpacing: '0.4px',
              fontSize: 12,
              fontWeight: 500,
              color: theme.palette.icon.tertiary,
            }}
          >
            {data?.nftContract.name}
          </Typography>
        </GeneralFlexBox>
      </CheckoutHeadingRow>
      <MiddleRow sx={{ paddingTop: 0 }}>
        <Content>
          <ContentHeading>
            <SubHeading
              sx={{
                fontSize: panel !== 'show' ? 14 : 20,
                fontWeight: panel !== 'show' ? 500 : 600,
              }}
              color={theme.palette.text.main}
            >
              {panel !== 'show' ? 'Your offer' : 'Offer made!'}
            </SubHeading>
            <SubHeading
              color={theme.palette.text.main}
              sx={{
                fontSize: panel !== 'show' ? 14 : 20,
                fontWeight: panel !== 'show' ? 500 : 600,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <CurrencyIcon currency="NRG" type="default" />
              <Typography variant="span" marginLeft="8px">
                {bidAmount} WNRG
              </Typography>
            </SubHeading>
          </ContentHeading>
          <ContentHeading>
            <SubHeading sx={{ paddingTop: 1 }} color={theme.palette.text.main}>
              Valid Until
            </SubHeading>
            <SubHeading sx={{ paddingTop: 1 }} color={theme.palette.text.main}>
              {moment(expiredDate * 1000).format('MMM DD, YYYY')}
            </SubHeading>
          </ContentHeading>
        </Content>
        {panel === OFFER_PROCESS_STEP.WETH_ENABLE ? (
          <EnableWNRG failed={wethFailed} onTryAgain={onApproveWETH} processing={wethProcessing} />
        ) : panel === OFFER_PROCESS_STEP.CONFIRM_OFFER ? (
          <ConfirmOffer
            failed={confirmFailed}
            onTryAgain={onConfirmOffer}
            processing={confirmProcessing}
          />
        ) : panel === OFFER_PROCESS_STEP.POST_OFFER ? (
          <OfferDetails />
        ) : null}
      </MiddleRow>
      {panel === 'show' && (
        <CheckoutFooterRow>
          <StandardButton
            variant="contained"
            width={199}
            height={48}
            onClick={closeModal}
            disableRipple
          >
            See Offer
          </StandardButton>
        </CheckoutFooterRow>
      )}
    </>
  );
};

export default OfferProcess;
