import { RoyaltyRegistryABI, RoyaltyRegistryStorageABI } from './abis';

/**
 *
 * 39797    - Energi Mainnet
 * 49797    - Energi Testnet
 * 1        - Ethereum Mainnet
 * 11155111 - Sepolia Testnet
 *
 */
export default {
  49797: {
    RoyaltyRegistry: {
      address: '0x10Bc2A05E547329C9114bC4a4c3Ecb5990E49ceB',
      ABI: RoyaltyRegistryABI,
    },
    RoyaltyRegistryStorage: {
      address: '0x39E9E099bbb85E84BD41961Abe44829CD1014283',
      ABI: RoyaltyRegistryStorageABI,
    },
  },
  39797: {
    RoyaltyRegistry: {
      address: '0x4E992E34dF70a3A903408Da308BDC1349277B6CD',
      ABI: RoyaltyRegistryABI,
    },
    RoyaltyRegistryStorage: {
      address: '0xffe2E29a70a7F1F1a019be3B5404e3195426aD43',
      ABI: RoyaltyRegistryStorageABI,
    },
  },
  11155111: {
    RoyaltyRegistry: {
      address: '0x133440ee738FdbbD4A1D26328853eE446bDbb699',
      ABI: RoyaltyRegistryABI,
    },
    RoyaltyRegistryStorage: {
      address: '0x33f2FA9fa795a7572E9204FB87a8066d48d8eB4e',
      ABI: RoyaltyRegistryStorageABI,
    },
  },
  1: {
    RoyaltyRegistry: {
      address: '',
      ABI: RoyaltyRegistryABI,
    },
    RoyaltyRegistryStorage: {
      address: '',
      ABI: RoyaltyRegistryStorageABI,
    },
  },
};
