import { createContext, useCallback, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { getBalance } from '@wagmi/core';

import wagmiConfig from 'wagmiConfig';
import { BALANCE_TOKEN_LIST } from 'constants/index';
import { usePriceContext } from './PriceContext';
import useWalletContext from 'hooks/useWalletContext';
import useWindowContext from 'hooks/useWindowContext';
import { formatNumber, setBalanceWithDecimals } from 'utils/numbers';

export const BalanceContext = createContext(null);

export const BalanceProvider = ({ children }) => {
  const [balances, setBalances] = useStateIfMounted([]);
  const { address, chainId, provider } = useWalletContext();
  const { coinPrice } = usePriceContext();
  const { active } = useWindowContext();

  const totalBalance = balances?.reduce((a, b) => a + b.value, 0);

  const getTokenBalances = useCallback(async () => {
    try {
      const promise = BALANCE_TOKEN_LIST.map(async (token) => {
        let balance = 0;
        let result;
        if (token.chains.includes(chainId)) {
          if (token.isNative) {
            result = await getBalance(wagmiConfig, { address, chainId });
          } else {
            let tokenAddress = token.addresses[chainId];
            result = await getBalance(wagmiConfig, {
              address,
              token: tokenAddress,
              chainId,
            });
          }
        }
        if (result?.value) {
          balance = setBalanceWithDecimals(result.value, result.decimals);
        }
        return {
          ...token,
          balance: formatNumber(Number(balance)),
          last_price: coinPrice, // TODO: get tokenPrice other than wrapped coin for ERC20
          value: Number(balance) * coinPrice,
        };
      });
      const balances = await Promise.all(promise);
      const assetsWithBalance = balances.filter(({ value }) => value > 0);
      setBalances(assetsWithBalance);
    } catch (error) {
      console.error(error);
    }
  }, [address, chainId, coinPrice, provider]);

  useEffect(() => {
    if (!active || !address) {
      return;
    }

    getTokenBalances();
  }, [active, address, getTokenBalances]);

  useEffect(() => {
    if (address && chainId) {
      getTokenBalances();
    }
  }, [address, chainId, coinPrice, getTokenBalances]);

  return (
    <BalanceContext.Provider
      value={{
        totalBalance,
        balances,
        getTokenBalances,
        wethBalance: balances[1]?.balance,
        ethBalance: balances[0]?.balance,
        ethLastPrice: balances[0]?.last_price,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};
