import React from 'react';
import {
  //LocalOffer as LocalOfferIcon,
  Dashboard as DashboardIcon,
  Person as ProfileIcon,
  //Notifications as NotificationsIcon,
  Security as SecurityIcon,
  List as MuiListIcon,
  FormatListBulleted as FormatListBulletedIcon,
  Apps as AppsIcon,
  Window as WindowIcon,
  Filter as CollectionIcon,
  X,
  Instagram,
  Language,
  SquareRounded as LargeBoxIcon,
  LocalFireDepartment as BurnIcon,
} from '@mui/icons-material';
import { Link } from '@mui/material';

import {
  MenuDiscordIcon,
  MenuTwitterIcon,
  MenuInstagramIcon,
  MenuRedditIcon,
  MenuYouTubeIcon,
  OfferIcon,
  TransferIcon,
  MintedIcon,
  SaleIcon,
  ListIcon,
  BidIcon,
  DiscordNewIcon,
  MetamaskIcon,
  CoinBaseIcon,
} from 'components/ImageComponent';
import { Profile, /*Notifications,*/ /*Offers,*/ AccountSupport } from 'pages/Settings/components';
import MyCollections from 'pages/Settings/components/MyCollections';

export const APP_VERSION = 'v2.0.0';

// Width for Filter side bar drawer
export const DRAWER_WIDTH = '360px';

export const ENERGI_SUPPORT_LINK =
  'https://discord.com/channels/398355554172534805/1157987899246137384';

const LINK_GMI_DOCS = 'https://docs.gonnamakeit.com/';

// closing time after leave menu
export const MENU_CLOSING_TIME = 100;

export const SIGNATURE_EXPIRY_TIME = 86400000; // 1 day

const environment = process.env.REACT_APP_BUILD_MODE;

export const CONFIG = {
  Testnet: {
    ETHEREUM_CHAIN_ID: 11155111,
    ENERGI_CHAIN_ID: 49797,
    ENERGI_LAUNCHPAD: 'https://launchpad.test.gonnamakeit.com/',
  },
  Mainnet: {
    ETHEREUM_CHAIN_ID: 1,
    ENERGI_CHAIN_ID: 39797,
    ENERGI_LAUNCHPAD: 'https://launchpad.gonnamakeit.com/',
  },
};

export const SUPPORTED_NETWORKS = {
  Energi: CONFIG[environment].ENERGI_CHAIN_ID,
  Ethereum: CONFIG[environment].ETHEREUM_CHAIN_ID,
};

const NETWORK_TO_COIN_MAP = {
  Energi: {
    Symbol: 'NRG',
    Name: 'Energi',
  },
  Ethereum: {
    Symbol: 'ETH',
    Name: 'Ether',
  },
};

export const DEFAULT_NETWORK = SUPPORTED_NETWORKS.Energi;
export const CHAIN_ID = parseInt(DEFAULT_NETWORK);
export const COIN = NETWORK_TO_COIN_MAP[process.env.REACT_APP_NETWORK_NAME];

export const DEFAULT_LAUNCHPAD = CONFIG[environment].ENERGI_LAUNCHPAD;

// All links within marketplace and outside - handle testnet/mainnet in env?
export const LINKS_LIST = {
  allNFTs: {
    name: 'All NFTs',
    link: '',
  },
  new: {
    name: 'New',
    link: '',
  },
  art: {
    name: 'Art',
    link: '',
  },
  domainNames: {
    name: 'Domain Names',
    link: '',
  },
  virtualWorlds: {
    name: 'Virtual Worlds',
    link: '',
  },
  tradingCards: {
    name: 'Trading Cards',
    link: '',
  },
  collectibles: {
    name: 'Collectibles',
    link: '',
  },
  sports: {
    name: 'Sports',
    link: '',
  },
  utility: {
    name: 'Utility',
    link: '',
  },
  profile: {
    name: 'Profile',
    link: '',
  },
  favorites: {
    name: 'Favorites',
    link: '',
  },
  myCollectibles: {
    name: 'My Collectibles',
    link: '',
  },
  settings: {
    name: 'Settings',
    link: '',
  },
  rankings: {
    name: 'Rankings',
    link: '',
  },
  activity: {
    name: 'Activity',
    link: '',
  },
  blog: {
    name: 'Blog',
    link: '',
  },
  partners: {
    name: 'Partners',
    link: '',
  },
  website: {
    name: 'Website',
    type: 'resources',
    link: '',
  },
  documentation: {
    name: 'Docs',
    link: '',
  },
  github: {
    name: 'Github',
    link: 'https://github.com/energicryptocurrency',
    external: true,
  },
  joinOurTeam: {
    name: 'Join Our Team',
    link: 'https://www.energi.world/join-our-team/',
    external: true,
  },
  contactUs: {
    name: 'Contact Us',
    link: ENERGI_SUPPORT_LINK,
    external: true,
  },
  twitter: {
    name: 'Twitter',
    link: 'https://twitter.com/gonnamakeitnfts',
    external: true,
  },
  discord: {
    name: 'Discord',
    link: 'https://discord.com/invite/sCtgNC3/',
    external: true,
  },
  telegram: {
    name: 'Telegram',
    link: 'https://t.me/GonnaMakeitNFTs',
    external: true,
  },
  reddit: {
    name: 'Reddit',
    link: 'https://www.reddit.com/r/energicryptocurrency/',
    external: true,
  },
  youtube: {
    name: 'Youtube',
    link: 'https://www.youtube.com/c/Energicrypto',
    external: true,
  },
  facebook: {
    name: 'Facebook',
    link: 'https://www.facebook.com/energicrypto/',
    external: true,
  },
  instagram: {
    name: 'Instagram',
    link: 'https://www.instagram.com/energicrypto',
    external: true,
  },
  linkedin: {
    name: 'Linkedin',
    link: 'https://www.linkedin.com/company/energi-core/',
    external: true,
  },
  aboutUs: {
    name: 'About Us',
    link: 'https://www.gonnamakeit.com/',
    external: true,
  },
  terms: {
    name: 'Terms',
    link: 'tos',
  },
  docs: {
    name: 'Docs',
    link: LINK_GMI_DOCS,
    external: true,
  },
  developerDocs: {
    name: 'Developer Docs',
    link: `${LINK_GMI_DOCS}docs/developers/`,
    external: true,
  },
  faq: {
    name: 'FAQ',
    link: `${LINK_GMI_DOCS}docs/faq/`,
    external: true,
  },
  guides: {
    name: 'GMI Guides',
    link: `${LINK_GMI_DOCS}docs/guides/`,
    external: true,
  },
  privacyPolicy: {
    name: 'Privacy Policy',
    link: 'https://gonnamakeit.com/privacy-policy/',
    external: true,
  },
};

// Social media links/icons for sidebar and mobile menu
export const SOCIAL_MEDIAS = [
  {
    ...LINKS_LIST['twitter'],
    icon: <MenuTwitterIcon />,
  },
  {
    ...LINKS_LIST['instagram'],
    icon: <MenuInstagramIcon />,
  },
  {
    ...LINKS_LIST['discord'],
    icon: <MenuDiscordIcon />,
  },
  {
    ...LINKS_LIST['reddit'],
    icon: <MenuRedditIcon />,
  },
  {
    ...LINKS_LIST['youtube'],
    icon: <MenuYouTubeIcon />,
  },
];

export const NAV_MENUS = [
  {
    id: 1,
    text: 'Collections',
    path: '/collections?type=top',
  },
  {
    id: 2,
    text: 'Portfolio',
    path: '/portfolio',
    submenu: false,
  },
  {
    id: 3,
    text: 'Activity',
    path: '/activity',
    submenu: false,
  },
  {
    id: 4,
    text: 'Airdrop',
    path: 'https://airdrop.gonnamakeit.com/',
    submenu: false,
  },
  {
    id: 5,
    text: 'Launchpad',
    path: DEFAULT_LAUNCHPAD,
    submenu: false,
  },
  {
    id: 6,
    text: 'Create',
    path: '/create',
    submenu: false,
    notShowInSideNav: true,
  },
  {
    id: 7,
    text: 'Resources',
    path: '',
    submenu: true,
  },
];

// marketplace - options to sort listed cards
export const SORT_OPTIONS = [
  { name: 'Listed: Recent', value: 'lastListingTimestamp', direction: 'ascending' },
  { name: 'Listed: Oldest', value: 'lastListingTimestamp', direction: 'descending' },
  { name: 'Price: Lowest', value: 'price', direction: 'ascending' },
  { name: 'Price: Highest', value: 'price', direction: 'descending' },
];

// marketplace - options to display type of cards
export const ITEMS_OPTIONS = [
  { name: 'Items', value: 'All_items' },
  { name: 'Single items', value: 'Single_items' },
  { name: 'Bundles', value: 'Bundles' },
];

// price history - options to price history time duration
export const PRICE_HISTORY_OPTIONS = [
  '7 days',
  '14 days',
  '30 days',
  '60 days',
  '90 days',
  'Last year',
  'All time',
];

const day = 24 * 60 * 60; // A day in seconds
export const FETCH_PERIOD = {
  '7 days': 7 * day,
  '14 days': 14 * day,
  '30 days': 30 * day,
  '60 days': 60 * day,
  '90 days': 90 * day,
  'Last year': 365 * day,
  'All time': Date.now() / 1000, // 'all time' timestamp
};

// Filter option for sort by date in rankings
export const LAST_PERIOD_OPTIONS = {
  'Last 10 minutes': 600,
  'Last 1 hour': 3600,
  'Last 6 hours': 21800,
  'Last 24 hours': 86400,
  'Last 7 days': 604800,
  'Last 30 days': 2592000,
};

export const COLLECTION_VOLUMES_TIMEFRAMES = {
  'Last 1 hour': '1h',
  'Last 6 hours': '6h',
  'Last 24 hours': '24h',
  'Last 7 days': '7d',
  'Last 30 days': '30d',
};

export const DEFAULT_TIMEFRAME = '7d';

export const COLLECTIONS_COLUMNS = [
  { field: 'rank', label: '#' },
  { field: 'name', label: 'Collection', align: 'left' },
  { field: 'floorPrice', label: 'Floor Price' },
  { field: 'topBid', label: 'Top Bid' },
  { field: 'change24h', label: '1D Change' },
  { field: 'change7d', label: '7D Change' },
  { field: 'volume24h', label: '1D Volume' },
  { field: 'volume7d', label: '7D Volume' },
  { field: 'owners', label: 'Owners' },
  { field: 'circulatingSupply', label: 'Supply' },
];

export const SORT_NETWORKS = [
  // { value: 'overall', label: 'Overall' },
  { value: 'gmi', label: 'GonnaMakeIt' },
  // { value: 'opensea', label: 'OpenSea' },
];

// accordion details of account support
export const ACCOUNT_SUPPORT_ACCORDIONS = [
  {
    summary: 'General help',
    detail: (
      <span>
        Visit our{' '}
        <Link
          href={ENERGI_SUPPORT_LINK}
          target="_blank"
          rel="noopener noreferer"
          sx={{ lineHeight: '18px' }}
        >
          help center
        </Link>{' '}
        to learn how to get started with buying, selling, and creating.
      </span>
    ),
  },
  {
    summary: 'Contact GonnaMakeIt Support',
    detail: (
      <span>
        {"Can't find the answers you’re looking for?"} <br />
        You can{' '}
        <Link
          href="https://discord.com/channels/398355554172534805/1157987899246137384"
          target="_blank"
          rel="noopener noreferer"
          sx={{ lineHeight: '18px' }}
        >
          submit a request
        </Link>{' '}
        here.
      </span>
    ),
  },
  {
    summary: 'Help with a compromised account',
    detail: (
      <span>
        If you believe your account has been compromised, let us know and we can lock your account.
        This will disable items in your wallet from being bought, sold, or transferred using
        GonnaMakeIt.{' '}
        <Link
          href={ENERGI_SUPPORT_LINK}
          target="_blank"
          rel="noopener noreferer"
          sx={{ lineHeight: '18px' }}
        >
          Learn more
        </Link>{' '}
        .
      </span>
    ),
  },
];

// compromised options for account support
export const COMPROMISED_OPTIONS = [
  { value: 1, text: 'I have unknown transactions on my account' },
  { value: 2, text: 'I have everything but believe my account is compromised' },
  { value: 3, text: 'Other' },
];

const iconTag = (ActivityIcon) => {
  return <ActivityIcon sx={{ width: 20, marginRight: 1, color: 'icon.secondary' }} />;
};

// Activity types for filter and display
export const ACTIVITY_TYPES = {
  sale: { name: 'Sale', icon: iconTag(SaleIcon) },
  listing: { name: 'List', icon: iconTag(ListIcon) },
  offer: { name: 'Offer', icon: iconTag(OfferIcon) },
  mint: { name: 'Mint', icon: iconTag(MintedIcon) },
  transfer: { name: 'Transfer', icon: iconTag(TransferIcon) },
  burn: { name: 'Burn', icon: iconTag(BurnIcon) },
  bid: { name: 'Bid', icon: iconTag(BidIcon) },
};

// Message to sign for authentication
export const AUTH_MESSAGE_TO_SIGN = (address, nonce) => {
  return `Welcome to GonnaMakeIt!\n\nClick to sign in and accept the GonnaMakeIt Terms of Service\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address: ${address}\n\nNonce: ${nonce}`;
};

// marketplace - options to display features of card
export const CARD_OPTIONS = [
  { name: 'Refresh metadata', value: 'REFRESH_METADATA', item: 'nft' },
  { name: 'Copy link', value: 'COPY_LINK', item: 'nft' },
  { name: 'Website', value: 'WEBSITE', item: 'nft' },
  { name: 'Report this item', value: 'REPORT', item: 'nft' },
];

export const activityFilterStatusOpen = [
  { name: 'Status', active: false },
  { name: 'Collections', active: false },
];

export const CollectionFilterOpen = [
  { name: 'Status', active: false },
  { name: 'Collections', active: false },
];

export const REWARDS_TABS = [
  '/rewards/referral_program',
  '/rewards/referral_program/apply_promo',
  '/rewards/referral_program/redeem',
];

export const RESOURCE_MENU = [
  {
    text: 'NFT Bridge',
    path: 'https://bridge.gonnamakeit.com/',
  },
  {
    text: 'For Investors',
    path: 'https://gonnamakeit.com/for-investors/',
  },
  /*{
    text: 'Rewards',
    path: '/rewards/referral_program',
    // submenus: REWARDS_MENU, TODO: Unhide when farming or listing is ready
    tabs: REWARDS_TABS,
  },*/
  {
    text: 'Help Center',
    path: ENERGI_SUPPORT_LINK,
  },
  /*{
    text: 'Referral stats',
    path: '/collections?type=referral',
  },*/
  {
    text: 'Energi Website',
    path: 'https://energi.world/',
  },
  {
    text: 'Docs',
    path: LINK_GMI_DOCS,
  },
];

// const AIRDROP_REFERRAL_WEBSITE = 'https://ref.gonnamakeit.com/referral';

/*export const REWARDS_MENU = [
  {
    text: 'Referral program',
    path: AIRDROP_REFERRAL_WEBSITE,
  },
  {
    text: 'Farming',
    path: '/rewards/farming',
  },
  {
    text: 'Listing rewards',
    path: '/rewards/listing-rewards',
  },
];*/

export const NAVBAR_MENUS = [
  {
    text: 'Collections',
    path: '/collections?type=top',
  },
  {
    text: 'Portfolio',
    path: '/portfolio',
  },
  {
    text: 'Activity',
    path: '/activity',
  },
  {
    text: 'Airdrop',
    path: 'https://airdrop.gonnamakeit.com/',
  },
  {
    text: 'Launchpad',
    path: DEFAULT_LAUNCHPAD,
  },
  {
    text: 'Resources',
    path: '',
    submenus: RESOURCE_MENU,
  },
];

export const STAT_SUB_NAVIGATIONS = [
  {
    text: 'Collections',
    link: 'top',
    icon: <CollectionIcon fontSize="13px" />,
  },
  /*
  * Hide for soft lunch:
  * https://git.energi.software/energi/tech/dweb/nft/marketplace/webapp/-/issues/491
  {
    text: 'Trending',
    link: 'trending',
  },
  */
  /*
  * Hide for soft lunch:
  * https://git.energi.software/energi/tech/dweb/nft/marketplace/webapp/-/issues/491
  {
    text: 'Favorites',
    link: 'favorites',
  },
  */
];

export const STAT_REWARDS_SUB_NAVIGATIONS = [
  {
    text: 'Referral rewards',
    link: 'referral',
  },
  {
    text: 'Airdrop referral rewards',
    link: 'airdrop',
  },
];

export const PAGE_SIZES = [100, 50, 20];

export const REPORT_OPTIONS = [
  { label: 'Fake collection or possible scam', value: 0 },
  { label: 'Explicit or sensitive content', value: 1 },
  { label: 'Spam', value: 2 },
  { label: 'Might be stolen', value: 3 },
  { label: 'Other', value: 4 },
];

export const RAW_COLLECTION = [
  {
    name: 'Crypto Punk',
    active: false,
    show: true,
    alt: 'Avatar',
    src: 'https://i.pravatar.cc/300',
  },
  {
    name: 'Energi Special',
    active: false,
    show: true,
    alt: 'Avatar',
    src: 'https://i.pravatar.cc/300',
  },
  {
    name: 'ABC Collection',
    active: false,
    show: true,
    alt: 'Avatar',
    src: 'https://i.pravatar.cc/300',
  },
  {
    name: 'Scoppy Do',
    active: false,
    show: true,
    alt: 'Avatar',
    src: 'https://i.pravatar.cc/300',
  },
]; // dummy data - Fetch from database

export const ACCOUNT_SETTINGS = [
  {
    slug: 'profile',
    icon: <ProfileIcon />,
    text: 'Profile',
    component: <Profile />,
    path: '/account/settings/profile',
  },
  /* TODO: enable when Notifications ready
  {
    slug: 'notifications',
    icon: <NotificationsIcon />,
    text: 'Notifications',
    component: <Notifications />,
    path: '/account/settings/notifications',
  },*/
  /* TODO: enable when Bids ready
  {
    slug: 'offers',
    icon: <LocalOfferIcon />,
    text: 'Offers',
    component: <Offers />,
    path: '/account/settings/offers',
  },*/
  {
    slug: 'collections',
    icon: <DashboardIcon />,
    text: 'My Collections',
    component: <MyCollections />,
    path: '/account/settings/collections',
  },
  {
    slug: 'account_support',
    icon: <SecurityIcon />,
    text: 'Account Support',
    component: <AccountSupport />,
    path: '/account/settings/support',
  },
];

export const FILTER_BOX_MAX_HEIGHT = 80;
export const DEFAULT_SIZE = 20;

export const ETHEREUM_TOKENS = ['ETH', 'WETH'];
export const ENERGI_TOKENS = ['NRG', 'WNRG', 'GMI'];
export const TOKEN_LIST = [...ETHEREUM_TOKENS, ...ENERGI_TOKENS];

export const BALANCE_TOKEN_LIST = [
  {
    symbol: 'ETH',
    name: 'Ether',
    chains: [1, 11155111],
    isNative: true,
    decimals: 18,
  },
  {
    symbol: 'NRG',
    name: 'Energi',
    decimals: 18,
    chains: [39797, 49797],
    isNative: true,
  },
  {
    symbol: 'WNRG',
    name: 'Wrapped Energi',
    chains: [39797, 49797],
    decimals: 18,
    isWrapped: true,
    addresses: {
      39797: '0xA55F26319462355474A9F2c8790860776a329aA4',
      49797: '0x184F7b12f29d675f34ff816528ADBfd0E0501Ad4',
    },
  },
  {
    symbol: 'WETH',
    name: 'Wrapped Ethereum',
    decimals: 18,
    chains: [1, 11155111],
    addresses: {
      1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      11155111: '0x7b79995e5f793a07bc00c21412e50ecae098e7f9',
    },
  },
  // {
  //   symbol: 'GMI',
  //   name: 'GonnaMakeIt',
  //   decimals: 18,
  //   addresses: {
  //     1: '0xCE6C188582b65560F64DBE86cd0E760C0cf088fc', // update value when GMI is deployed on Mainnet
  //     5: '0xCE6C188582b65560F64DBE86cd0E760C0cf088fC',
  //   },
  // },
];

export const NETWORK_NAME = {
  1: 'Ethereum',
  5: 'Goerli',
  47: 'Kovan',
  11155111: 'Sepolia',
  39797: 'Energi',
  49797: 'Energi Testnet',
  default: 'Default Network',
};

export const REDIRECT_LINK_EXPLORER = {
  1: 'https://etherscan.io',
  5: 'https://goerli.etherscan.io',
  47: 'https://kovan.etherscan.io',
  11155111: 'https://sepolia.etherscan.io',
  39797: 'https://explorer.energi.network',
  49797: 'https://explorer.test.energi.network',
  default: '',
};

export const LINKS = {
  twitter: 'https://twitter.com/',
  instagram: 'https://instagram.com/',
  energiExplorer: `${REDIRECT_LINK_EXPLORER[process.env.REACT_APP_NETWORK_ID]}/address/`,
};

// List id for newsletter email list on sendgrid. Need to be sent with the post request
// Type: string
export const NEWSLETTER_EMAIL_LIST_ID = '18944388';

export const REPORT_TOAST_DURATION = 4000;

// WETH contract address - indexed by chainId
export const WETH_PROXY_ADDRESS = {
  11155111: '0x7b79995e5f793a07bc00c21412e50ecae098e7f9',
  1: '',
};

// WNRG contract address - indexed by chainId
export const WNRG_PROXY_ADDRESS = {
  49797: '0x184f7b12f29d675f34ff816528adbfd0e0501ad4', // Testnet
  39797: '0xa55f26319462355474a9f2c8790860776a329aa4', // Mainnet
};

export const WETH_GOERLI_TOKEN = {
  address: WETH_PROXY_ADDRESS[5],
  chainId: 5,
  decimals: 18,
  logo: 'assets/images/ETHRed.svg',
  name: 'Wrapped Ethereum',
  symbol: 'WETH',
};

// ExchangeProxy contract address - indexed by chainId
export const EXCHANGE_PROXY_ADDRESS = {
  1: '',
  11155111: '0x69131badf12db01116251018cc1015d2ff7f4833',
  39797: '0x0D09CDb5901467FA531Aa7b1D8AC331Ab9f1F70B',
  49797: '0x4604889e9a4edb5A959A100cd7dC85BC565fC34e',
};

export const EXCHANGE_HELPER_PROXY_ADDRESS = {
  1: '',
  11155111: '0x151448374ce04cab1bc2737c9ada6b6fcc71cf7e',
  39797: '0xA46c1A4f05609C36F0389d6fC2cEB2e5aE86b487',
  49797: '0x28F0aaC5bC164D24069421E4516F6eB0740Bd3e1',
};

export const GMI_GOERLI_TOKENS = {
  address: '0xCE6C188582b65560F64DBE86cd0E760C0cf088fC',
  chainId: 5,
  decimals: 18,
  logo: 'assets/images/NRGLogoDark.svg',
  name: 'GonnaMakeIt',
  symbol: 'GMI',
};

export const GMI_TOKEN_DETAILS = {
  Mainnet: {
    address: '0xCE6C188582b65560F64DBE86cd0E760C0cf088fC',
    symbol: 'GMI',
    decimals: 18,
    image: 'https://explorer.energi.network/images/tokens/NRG.svg',
  },
  Testnet: {
    address: '0xCE6C188582b65560F64DBE86cd0E760C0cf088fC',
    symbol: 'GMI',
    decimals: 18,
    image: 'https://explorer.energi.network/images/tokens/NRG.svg',
  },
};

export const SUSHISWAP_GMI =
  'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0xCE6C188582b65560F64DBE86cd0E760C0cf088fC&chainId=5';

export const ETHERSCAN_ENERGI =
  'https://etherscan.io/token/0x1416946162b1c2c871a73b07e932d2fb6c932069';

export const POOLS_COUNT = 7;

export const NetworkContextName = 'NETWORK';

export const FARMING_PROXY_ADDRESS = {
  Mainnet: {
    '6 Months': '0xe09F8a908F9ECB6aD7232608D3d9cf8732bE506f',
    '1 Year': '0x378651f2f17dBDCC2D2a704C5b6ec88f995FF33d',
    '2 Years': '0xC54fEc8965567b0E624D735fcd0Bf921cD8A2565',
    '3 Years': '0x2442176111629b18e4E3ae68B2999595D023B535',
    '5 Years': '0x2b7902724e749c304CE90a8B2E093a5157A94026',
  },
  Testnet: {
    '6 Months': '0xe09F8a908F9ECB6aD7232608D3d9cf8732bE506f',
    '1 Year': '0x378651f2f17dBDCC2D2a704C5b6ec88f995FF33d',
    '2 Years': '0xC54fEc8965567b0E624D735fcd0Bf921cD8A2565',
    '3 Years': '0x2442176111629b18e4E3ae68B2999595D023B535',
    '5 Years': '0x2b7902724e749c304CE90a8B2E093a5157A94026',
  },
};

// TODO: Doris suggested to Brian about renaming this to "GMI-WETH LP Token SushiSWAP"
export const FARMING_ADDRESS = {
  Mainnet: '0xeE39CFd750A6fa5b4c06C2600e20782602278f7F',
  Testnet: '0xeE39CFd750A6fa5b4c06C2600e20782602278f7F',
};

// ExchangeProxy contract asset classes
export const CONTRACT_ASSET_CLASSES = {
  C_ETH: -1161908566,
  C_ETH_STR: '0xaaaebeba',
  C_WETH: 260708671,
  C_WETH_STR: '0x0f8a193f',
  C_ERC20: -2074220406,
  C_ERC20_STR: '0x8ae85d84',
  C_ERC721: 1176612211,
  C_ERC721_STR: '0x73ad2146',
  C_ERC1155: 1085684631,
  C_ERC1155_STR: '0x973bb640',
  C_V1: 1715610444,
  C_V1_STR: '0x6642234c',
  C_ff: -1,
  C_ff_STR: '0xffffffff',
};

// Zero address
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
// Dead address
export const DEAD_ADDRESS = '0x000000000000000000000000000000000000dead';

// EIP712 signer message
export const EIP712_SIGNER_MESSAGE = {
  types: {
    AssetType: [
      { name: 'assetClass', type: 'bytes4' },
      { name: 'data', type: 'bytes' },
    ],
    Asset: [
      { name: 'assetType', type: 'AssetType' },
      { name: 'value', type: 'uint256' },
    ],
    Order: [
      { name: 'maker', type: 'address' },
      { name: 'makeAsset', type: 'Asset' },
      { name: 'taker', type: 'address' },
      { name: 'takeAsset', type: 'Asset' },
      { name: 'salt', type: 'uint256' },
      { name: 'start', type: 'uint256' },
      { name: 'end', type: 'uint256' },
      { name: 'dataType', type: 'bytes4' },
      { name: 'data', type: 'bytes' },
      { name: 'collectionBid', type: 'bool' },
    ],
  },
  primaryType: 'Order',
  domain: {
    name: 'Energi',
    version: '1',
    chainId: 0, // current network chain id
    verifyingContract: '', // contract that will verify signature
  },
  message: '', // maker order
};

export const RETRY_COUNT = 120; // Number of times called getTransactionRecipt function

export const SLEEP_INTERVAL = 1000; //

export const MAX_GAS_ALLOWANCE = BigInt(3500000);

export const MAX_APPROVAL_AMOUNT =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const FARMING_POOL_TIME = [
  { duration: '6 Months', duration_short: '6M' },
  { duration: '1 Year', duration_short: '1Y' },
  { duration: '2 Years', duration_short: '2Y' },
  { duration: '3 Years', duration_short: '3Y' },
  { duration: '5 Years', duration_short: '5Y' },
];

export const TABS = [
  {
    text: 'Claimable',
  },
  {
    text: 'Pending',
  },
];

// This contract has the same address on all testnets.
export const SUSHISWAP_ROUTER_ADDRESS = {
  Mainnet: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  Testnet: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
};

export const FARMING_POOL_EST_APY = {
  '6 Months': '30%',
  '1 Year': '80%',
  '2 Years': '400%',
  '3 Years': '1000%',
  '5 Years': '5000%',
};

export const SOCIAL_MEDIA_COLORS = {
  Twitter: '#1DA1F2',
  Reddit: '#FF5700',
  Facebook: '#4267B2',
  WhatsApp: '#25D366',
};

export const RESEND_EMAIL_VERIFICATION_DELAY = 60000; // 1 minute in milliseconds
export const LOCAL_STORAGE_TIMER_KEY = 'timerData';

export const ACTIVE_STEPS = {
  CALL_APPROVE: 0,
  INTERACT_METAMASK_APPROVE: 1,
  CONFIRM_TRANSACTION_APPROVE: 2,
  CALL_FUNCTION: 3,
  COMPLETE_TRANSACTION: 4,
};

export const AIRDROP_REWARDS = {
  AVAILABLE: '--',
  CLAIMED: '--',
  BONUS_AVAILABLE: '--',
  BONUS_CLAIMED: '--',
};

export const SUPPORT_EMAIL = 'support@gonnamakeit.com';

export const NETWORK = {
  1: {
    name: 'Ethereum',
    symbol: 'ETH',
  },
  11155111: {
    name: 'Sepolia',
    symbol: 'ETH',
    exchange_address: '0x151448374ce04cab1bc2737c9ada6b6fcc71cf7e',
  },
  39797: {
    name: 'Energi Mainnet',
    symbol: 'NRG',
    exchange_address: '0xA46c1A4f05609C36F0389d6fC2cEB2e5aE86b487',
  },
  49797: {
    name: 'Energi Testnet',
    symbol: 'NRG',
    exchange_address: '0x28f0aac5bc164d24069421e4516f6eb0740bd3e1',
  },
};

export const NETWORK_DEFAULT = NETWORK[DEFAULT_NETWORK];

// Files
export const FILE_MAX_SIZE = 10 * 1024 * 1024; // 10 MB

export const TOTAL_ROYALTY_PERCENTAGE = {
  MIN: 0.5,
  MAX: 7,
  MULTIPLIER: 100,
};

export const DECIMALS_REGEX = /-?\d*\.?\d{0,3}/i;

export const COLLECTION_VIEWS = [
  { value: 'condensed', icon: <MuiListIcon /> },
  { value: 'expanded', icon: <FormatListBulletedIcon /> },
];

export const SINGLE_COLLECTION = {
  FETCH_RESULT_LIMIT: 50,
  DEFAULT_VIEW: 'BigThumbnail',
  CONTENT_VIEWS: {
    ShortList: <MuiListIcon />,
    LargeList: <FormatListBulletedIcon />,
    SmallThumbnail: <AppsIcon />,
    BigThumbnail: <WindowIcon />,
  },
  MOBILE_CONTENT_VIEWS: {
    LargeList: <FormatListBulletedIcon />,
    SmallThumbnail: <WindowIcon />,
    BigThumbnail: <LargeBoxIcon sx={{ fontSize: 26 }} />,
  },
  CONTENT_TABS: {
    ITEMS_TAB: {
      label: 'Items',
      value: 0,
    },
    BIDS_TAB: {
      label: 'Bids',
      value: 1,
    },
    ANALYTICS_TAB: {
      label: 'Analytics',
      value: 2,
    },
    ACTIVITY_TAB: {
      label: 'Activity',
      value: 3,
    },
    HOLDERS_TAB: {
      label: 'Holders',
      value: 4,
    },
  },
};

export const ACTIVITY_TABLE_COLUMNS = [
  {
    field: 'activity',
    label: 'Event',
    sx: { position: 'sticky', left: 0, zIndex: 5 },
  },
  { field: 'item', label: 'Item' },
  { field: 'price', label: 'Price', sx: { textAlign: 'right' } },
  { field: 'from', label: 'From', sx: { pl: 6 } },
  { field: 'to', label: 'To' },
  { field: 'time', label: 'Time' },
];

export const LISTING_DURATIONS = [
  {
    key: '1 Hour',
    value: 1,
  },
  {
    key: '1 Day',
    value: 1 * 24,
  },
  {
    key: '3 Days',
    value: 3 * 24,
  },
  {
    key: '7 Days',
    value: 7 * 24,
  },
  {
    key: '30 Days',
    value: 30 * 24,
  },
  {
    key: '6 Months',
    value: 180 * 24,
  },
];

export const RARITIES_PERCENTILE = {
  mythic: 'Top 1%',
  legendary: 'Top 3%',
  epic: 'Top 10%',
  rare: 'Top 25%',
  uncommon: 'Top 50%',
  common: 'Bottom 50%',
};

export const PORTFOLIO_FILTER = ['Show All', 'Only Listed'];

export const PORTFOLIO_TABS = {
  Items: 'Items',
  Activity: 'Activity',
  Offers: 'Offers',
  Bids: 'Bids',
};

export const ORDER_TYPES = {
  LISTING: 'listing',
  OFFER: 'offer',
};

export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later';

export const CATEGORY_OPTIONS = ['Art', 'Gaming', 'Membership', 'PFPs', 'Photography', 'Music'];

export const SOCIAL_LINKS = [
  {
    name: 'twitterUsername',
    adornment: 'https://x.com/',
    placeholder: 'my-first-collection',
    icon: <X />,
  },
  {
    name: 'instagramUsername',
    adornment: 'https://www.instagram.com/',
    placeholder: 'my-first-collection',
    icon: <Instagram />,
  },
  {
    name: 'discordUrl',
    placeholder: 'https://discord.gg/handle',
    icon: <DiscordNewIcon color="#929292" />,
  },
  { name: 'externalUrl', placeholder: 'https://yoursite.xyz', icon: <Language /> },
];

/**
 * The maximum number of NFTs that can be processed in a single transaction.
 * due to 40_000_000 max chain gas limit
 */
export const MAXIMUM_NFT_LIMIT = {
  // 1 mint is around 200_000 to 300_000 gas
  MINTS: 260,
  // 1 sweep item is around 600_000 to 700_000 gas
  SWEEPS: 60,
  // 1 bid item is around 850_000 to 950_000 gas
  BIDS: 40,
};

export const WALLET_OPTIONS_ICONS = {
  MetaMask: <MetamaskIcon />,
  'Coinbase Wallet': <CoinBaseIcon />,
};

export const MAX_FILE_SIZE = {
  label: '10 MB',
  value: 10 * 1024 * 1024,
};

export const METAMASK_DOWNLOAD_URL = 'https://metamask.io/download.html';

export const METAMASK_GUIDE_URL =
  'https://wiki.energi.world/docs/quickstart/getting-started-with-metamask';
