import { useCallback } from 'react';

import { CHAIN_ID } from 'constants';
import useWalletContext from 'hooks/useWalletContext';

// checks and request user to switch network
export const useSwitchNetwork = () => {
  const { chainId, switchToNetwork, storedChain } = useWalletContext();
  const switchNetwork = useCallback(async () => {
    try {
      if (chainId !== storedChain) {
        await switchToNetwork(storedChain);
      }
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }, [CHAIN_ID, switchToNetwork, storedChain]);
  return switchNetwork;
};
